<template>
  <div class="bookhouse">
    <div class="book-contect">
      <div class="book-title">Booking Requirements</div>
      <div class="bk_contant">
        <el-form :model="data">
          <div class="row item-row">
            <el-form-item>
              <p class="book-form-title">Turnover Day</p>
              <el-select v-model="data.turnover_day" placeholder="Please choose" >
                <el-option v-for="item in week" :key="item" :label="item" :value="item" ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <p class="book-form-title">Pets </p>
              <el-select v-model="data.pet_allow" placeholder="Please choose" @change="changePet" >
                <el-option v-for="(item, index) in pets" :key="index" :label="item.label" :value="item.value" ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <p class="book-form-title">Cleaning Hours Included</p>
              <el-select v-model="data.cleaning_hours" placeholder="Please choose" >
                <el-option v-for="index in 100" :key="index" :value="index">{{ index }}</el-option>
              </el-select>
            </el-form-item>

            <el-form-item v-show="showPet">
              <p class="book-form-title">Pet Fee Amount</p>
              <el-input  v-model="data.pet_fee" type="number"/>
            </el-form-item>

            <el-form-item>
              <p class="book-form-title">Minimum Night Stay</p>
              <el-select v-model="data.min_night_stay" placeholder="Please choose" >
                <el-option v-for="index in 100" :key="index" :value="index">{{ index }}</el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <p class="book-form-title">Security Deposit Minimum</p>
              <el-input v-model="data.min_security_deposit" type="number"></el-input>
            </el-form-item>

            <el-form-item>
              <p class="book-form-title">Security Deposit %</p>
              <el-select v-model="data.security_deposit_percentage" placeholder="Please choose" >
                <el-option v-for="item in securitypercentage" :key="item.id" :label="item.name" :value="item.id" ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <p class="book-form-title">Cleaning Fee</p>
              <el-input v-model="data.cleaning_fee" type="number"></el-input>
            </el-form-item>
            <el-form-item>
              <p class="book-form-title">Payment(# of)</p>
              <el-select  v-model="data.payment_number" placeholder="Please choose" >
                <el-option v-for="item in 4" :key="item" :label="item" :value="item" ></el-option>
              </el-select>
            </el-form-item>
            <div class="row date-item">
                <el-form-item prop="checkin_time" >
                  <p class="book-form-title">Check-in Time</p>
                  <el-time-picker value-format="HH:mm:ss" format="h:mm A" v-model="data.checkin_time" placeholder="" >
                  </el-time-picker>
                </el-form-item>
                <el-form-item prop="checkout_time" >
                  <p class="book-form-title">Check-out Time</p>
                  <el-time-picker value-format="HH:mm:ss" format="h:mm A" v-model="data.checkout_time" placeholder="" >
                  </el-time-picker>
                </el-form-item>
              </div>
              <el-form-item>
              <p class="book-form-title">Commission %</p>
              <el-input type="number" v-model="data.commission_percentage" @input="limitNumber" >
                <!-- <strong slot="prefix">%</strong> -->
              </el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
    <div class="bk_btn">
      <!-- <el-link @click="preStep" :underline="false">
          <i class="el-icon-arrow-left"></i>
          Back
        </el-link>
        <el-button @click="save" v-loading.fullscreen.lock="loading"
          >Save</el-button
        >
        <el-link @click="nextStep" :underline="false">
          Next
          <i class="el-icon-arrow-right"></i>
        </el-link> -->
      <el-button class="primary-button" @click="save" v-loading.fullscreen.lock="loading" >
        <p class="row btn-row">
          <span>Confirm</span><i class="el-icon-right"></i>
        </p>
      </el-button>
    </div>
  </div>
</template>
<script>
import { apiGetListing, apiUpdateListing } from "../../API/api";
export default {
  data() {
    return {
      week: [ "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", ],
      data: {
        checkin_time:"15:00:00",
        checkout_time:"10:00:00"
      },
      pets: [
        {
          value: 'true',
          label: "Yes",
        },
        {
          value: 'false',
          label: "No",
        },
        {
          value: 'ask_owner',
          label: "Ask Owner",
        },
        // {
        //   value: "considered",
        //   label: "Considered",
        // },
      ],
      showPet: false,
      loading: false,
      securitypercentage: [
        { id: 0.05, name: "5%" },
        { id: 0.1, name: "10%" },
        { id: 0.15, name: "15%" },
        { id: 0.2, name: "20%" },
      ],
      paymentfinal: [
        { id: 7, name: "7 days before arrival" },
        { id: 14, name: "14 days before arrival" },
        { id: 30, name: "30 days before arrival" },
        { id: 45, name: "45 days before arrival" },
        { id: 60, name: "60 days before arrival" },
        { id: 75, name: "75 days before arrival" },
        { id: 90, name: "90 days before arrival" },
      ],
      depositdate: [
        { id: 7, name: "7 days after departure" },
        { id: 14, name: "14 days after departure" },
        { id: 21, name: "21 days after departure" },
        { id: 30, name: "30 days after departure" },
        { id: 45, name: "45 days after departure" },
        { id: 60, name: "60 days after departure" },
      ],
    };
  },
  methods: {
     limitNumber(val) {
      if (val > 100) {
        this.data.commission_percentage = 100;
      } else if (val < 0) {
        this.data.commission_percentage = 0;
      }
    },
    getListing() {
      apiGetListing(this.$route.params.id).then((res) => {
        this.$store.commit("setListing", JSON.parse(JSON.stringify(res)));
        this.data = res.requirement;
         this.data.commission_percentage = this.data.commission_percentage * 100;
        if(this.data.pet_allow==="true" || this.data.pet_allow===true){
          this.showPet = true;
          } else {
            this.showPet = false;
          }
      });
    },
    preStep() {
      this.$router.push({
        name: "property-photo",
        params: { id: this.$route.params.id },
      });
    },
    nextStep() {
      this.$router.push({
        name: "property-service",
        params: { id: this.$route.params.id },
      });
    },
    save() {
      this.loading = true;
      if(!this.data.pet_allow) this.data.pet_fee = 0
       this.data.commission_percentage = this.data.commission_percentage / 100;
      apiUpdateListing(this.$route.params.id, { requirement: this.data })
        .then((res) => {
          this.$store.commit("setListing", JSON.parse(JSON.stringify(res)));
          this.data = res.requirement;
           this.data.commission_percentage = this.data.commission_percentage * 100;
          this.$message.success("Save successfully");
        })
        .catch((error) => {
          this.$message.error("Save failed");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changePet(val) {
      if (val === "true"||val===true) {
        this.showPet = true;
      } else {
        this.showPet = false;
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      // if (this.$store.state.listing.listing_id === this.$route.params.id - 0) {
      //   this.data = this.$store.state.listing.requirement;
      // } else {
        this.getListing();
      // }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";
.bookhouse {
  /deep/input[type=number] {  
    -moz-appearance:textfield;  
  }  
  /deep/input[type=number]::-webkit-inner-spin-button,  
  /deep/input[type=number]::-webkit-outer-spin-button {  
      -webkit-appearance: none;  
      margin: 0;  
  }  
  .book-title {
    text-align: left;
    font-family: "Roboto-Bold", sans-serif;
    font-size: 18px;
    color: #36425d;
  }
  .bk_contant {
    margin: 20px 0 0 0;
    background: #fff;
    .item-row {
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      .date-item{
        margin-top: 0;
        justify-content: space-between;
        width: calc(calc(100% - 20px) / 2);
        @media (max-width:1200px) {
          width: 100%;
        }
      }
      /deep/ .el-form-item {
        text-align: left;
        width: calc(calc(100% - 20px) / 2);
        .el-select,
        .el-input {
          width: 100%;
        }
        .el-input__inner {
          height: 40px;
          line-height: normal;
        }
      }
      .book-form-title {
        margin: 0;
        line-height: 20px;
      }
    }

    /deep/ .el-link.el-link--default {
      font-size: 20px;
    }
    .bk_checkall {
      width: 100%;
      text-align: left;
      /deep/ .el-checkbox-button,
      /deep/ .el-checkbox-button__inner {
        width: 80%;
        max-width: 200px;
      }
      /deep/ .el-checkbox-button__inner {
        background: #f2f2f2;
        &:hover {
          background-color: #f2f2f2;
          color: #333;
          border-color: none;
        }
      }
      /deep/ .el-checkbox-button.is-checked:first-child
        .el-checkbox-button__inner {
        background: #678993;
        color: #fff;
        border-color: #678993;
      }
      /deep/ .el-checkbox-button.is-focus .el-checkbox-button__inner {
        border-color: #f2f2f2;
      }
      /deep/ .el-checkbox-button:last-child .el-checkbox-button__inner {
        width: 100%;
      }
      /deep/ .el-row {
        margin-top: 0px;
      }
      /deep/ .grid-content {
        margin-top: 10px;
      }
    }
    @media (max-width: 850px) {
      .bk_restir,
      .bk_checkall {
        width: 80%;
      }
    }
    @media (max-width: 660px) {
      .bk_btn .el-button {
        width: 50%;
      }
    }
    
  }
  .book-contect{
    position: relative;
    padding: 20px;
    min-height: 600px;
    &::after{
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border: 1px solid #E1E9EF;
    }
  }
 
 
  .bk_btn {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    height: 80px;
    align-items: center;
    padding: 0 20px;
    /deep/ .el-button {
      .btn-row {
        width: 100px;
        margin: 0;
        justify-content: space-between;
        i {
          font-family: "Roboto-Bold", sans-serif;
          font-size: 20px;
        }
      }
    }
  }
  @media (max-width:992px) {
    .bk_contant .item-row {
      display: block;
      .date-item .el-form-item:last-of-type{margin-left: 10px;}
      /deep/ .el-form-item {width: 100%;}
    }
  }
}
</style>